var masonry = undefined
function mason() {
if (masonry != undefined) {
   masonry.reset()
}

masonry = new MiniMasonry({
   container: '.mg',
   baseWidth: 400, // maybe determine dynamically?
   wedge: false
});
}
//window.mason = mason
mason()
